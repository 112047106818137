// Clase del objeto Season.
import InfoSeason from "./InfoSeason";
import Control from "./Control";
import Farm from "./Farm";

class Season {
    // Constructor del objeto Season.
    constructor(props){
        this.type = "Season";
        this.farm = (props.farm !== undefined ? new Farm(props.farm) : "");
        this.info = (props.info !== undefined ? new InfoSeason(props.info) : "");
        this.team = [];
        if (props.team !== undefined) {
            for (let i in props.team) {
                this.team.push(new Control(props.team[i]));
            }
        }
    }

    // Obtiene el id de la temporada.
    getId = () => {
        return (this.info !== undefined && this.info !== "" ? this.info.getId() : "");
    }

    // Obtiene el id de la explotación.
    getFarmId = () => {
        return (this.farm !== undefined && this.farm !== "" ? this.farm.getId() : "");
    }

    // Obtiene el objeto info.
    getInfo = () => {
        return this.info;
    }

    // Obtiene el año de inicio de la temporada.
    getYear = () => {
        return (this.info !== undefined && this.info !== "" ? this.info.getYear() : "");
    }

    // Obtiene el nombre de la explotación.
    getName = () => {
        return (this.farm !== undefined && this.farm !== "" ? this.farm.getName() : "");
    }

    // Obtiene el team de la temporada.
    getTeam = () => {
        return this.team;
    }
}

export default Season;