import {datetimeToString} from "../functions/Basic";
import Organization from "./Organization";
import Metadata from "./Metadata";

// Clase del objeto InfoSeason.
class InfoSeason {
    // Constructor del objeto InfoSeason.
    constructor(props){
        this.type = "InfoSeason";
        this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
        this.inidate = (props.inidate !== "" && props.inidate !== undefined ? props.inidate : datetimeToString(new Date()));
        this.enddate = (props.enddate !== "" && props.enddate !== undefined ? props.enddate : "");
        this.org = (props.org !== null ? new Organization(props.org) : null);
        this.year = (props.year !== "" && props.year !== undefined ? props.year : "");
        this.siex = (props.siex !== undefined ? props.siex : 0);
        this.metadata = [];
        for (let i in props.metadata){
            this.metadata.push(new Metadata(props.metadata[i]));
        }
    }

    // Obtiene el id de la temporada.
    getId = () => {
        return (this.id !== undefined && this.id !== "" ? this.id : "");
    }

    // Obtiene el año de inicio de la temporada.
    getYear = () => {
        return this.year;
    }

    // Obtiene el objeto organizacion.
    getOrg = () => {
        return this.org;
    }
}

export default InfoSeason;