import React from "react";

const CustomDropdown = ({ options, selected, onChange }) => {
  const handleChange = (event) => {
    const selectedId = parseInt(event.target.value, 10);
    let selectedOption = null
    if (selectedId !== -1){
      selectedOption = options.find(option => option?.getId() === selectedId);
    }
    onChange(selectedOption); // Llama a la función onChange para actualizar el estado en el componente principal
  };

  return (
    <div className="relative w-full"> 
      <select
        className="w-full px-4 py-2 border rounded-lg shadow bg-white cursor-pointer"
        value={ selected === undefined ? "0" : (selected === null ? "-1" : selected.getId())} // El valor que está seleccionado proviene del estado del componente principal
        onChange={handleChange} // Cuando el valor cambia, se invoca handleChange
        style={{ width: "100%" }}
      >
        <option value="0" disabled>
          Selecciona una opción
        </option>
        {options.map((option) => {
          if (option === null) {
            return (
              <option key="-1" value="-1">
                Sin organización
              </option>
            );
          }
          return (
            <option key={option.getId()} value={option.getId()}>
              {option.getName()} 
            </option>
          )
        })}
      </select>
    </div>
  );
};


export default CustomDropdown;
