// Clase del objeto Key.

import KTP from "./KTP";
import UserMetadata from "./UserMetadata";
import TypeKey from "./TypeKey";
import Organization from "./Organization";

class Key {
    // Constructor del objeto Key.
    constructor(props) {
        this.type = "Key";
        if (props !== undefined && props !== null) {
            this.id = (props.id !== "" && props.id !== undefined ? props.id : "-1");
            this.subtype = new TypeKey(props.subtype);
            this.value = (props.value !== "" && props.value !== undefined ? props.value : "");
            this.nf = (props.nf !== "" && props.nf !== undefined ? props.nf : "1");
            this.org = (props.org !== null ? new Organization(props.org) : null);
            this.user = (props.user !== null ? new UserMetadata(props.user) : null);
            this.premium = [];
            for (let i in props.premium){
                this.premium.push(new KTP(props.premium[i]));
            }
        } else {
            this.id = "-1";
            this.subtype = null;
            this.value = "";
            this.nf = "1";
            this.org = null;
            this.user = null;
            this.premium = [];
        }
    }

    // Obtiene el id de la key.
    getId = () => {
        return this.id;
    }

    // Obtiene el valor de la key.
    getValue = () => {
        return this.value;
    }

    // Obtiene el tipo de key de la key.
    getSubtype = () => {
        return this.subtype;
    }

    // Establece el tipo de key de la key.
    setSubtype = (subtype) => {
        this.subtype = new TypeKey(subtype);
    }

    // Obtiene la organización de la key.
    getOrganization = () => {
        return this.org;
    }

    // Obtiene el número de explotaciones permitido en la key.
    getNF = () => {
        return this.nf;
    }

    // Establece el número de explotaciones permitido en la key.
    setNF = (nf) => {
        this.nf = nf;
    }

    // Obtiene los tipos de premium de la key.
    getPremium = () => {
        return this.premium;
    }

    // Establece los tipos de premium de la key.
    setPremium = (premium) => {
        this.premium = premium;
    }

    // Obtiene el usuario que tiene asignada la key.
    getUser = () => {
        return this.user;
    }

    // Devuelve si la clave es de tipo soporte.
    isSupport = () => {
        return (this.subtype.getId() === 1);
    }
}

export default Key;