import Strings from "../strings/Strings";
// Clase que contiene las peticiones realizadas en la web.

/********************************************************************************
 * Servicios GET                                                                *
 ********************************************************************************/
// Realiza la petición de la base de datos.
export function getInfoDatabase(){
    let strings = new Strings();
    let options = {
        method: 'GET',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
    };
    return fetch(strings.API_URL + "master/infoserver/show/", options);
}

// Realiza la petición para obtener el usuario activo.
export function getSysAdmin(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'oauth/sysadmin/show', options);
}

// Realiza la petición del dashboard con las estadísticas.
export function getDashboard(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/dashboard/global/', options);
}

// Realiza la petición del dashboard con las estadísticas del usuario pasado.
export function getUserDashboard(id){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/dashboard/user/' + id, options);
}

// Realiza la petición de la lista de usuarios del sistema.
export function getUserList(page_size, page, query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/user/list/async?page=' + page + '&page_size=' + page_size +
        (query !== '' ? '&qg1[or]=id,name,loginname,email,surname,nif&name[in]=' + query + '&loginname[in]=' + query +
            '&email[in]=' + query + '&surname[in]=' + query + '&nif[in]=' + query + '&id[eq]=' + query : ''), options);
}

// Realiza la petición de la lista de usuarios del sistema.
export function getAllUserList(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/user/list/async/', options);
}

// Realiza la petición de la lista de usuarios del sistema que cumplen con el filtro.
export function getUserListFilter(query, isRegistered){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/user/list/?qg1[or]=name,loginname,email,surname&name[in]=' + query + '&loginname[in]=' + query +
    '&email[in]=' + query + '&surname[in]=' + query + (isRegistered ? '&registered' : ''), options);
}

// Realiza la petición de la lista de usuarios que el usuario está asesorando.
export function getStaffUserList(userId, page_size, page, query, selVal){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    let filterField;
    let groupField;
    switch(selVal){
        case "username":
            groupField = 'loginname';
            filterField = '&loginname[in]=';
            break;
        case "email":
            groupField = 'email';
            filterField = '&email[eq]=';
            break;
        case "name":
            groupField = 'name';
            filterField = '&name[in]=';
            break;
        case "surname":
            groupField = 'surname';
            filterField = '&surname[in]=';
            break;
        case "nif":
            groupField = 'nif';
            filterField = '&nif[in]=';
            break;
        default:
            groupField = 'loginname';
            filterField = '&loginname[in]=';
            break;
    }
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'oauth/user/related/list?userid=' + userId + '&page_size=' + page_size + '&page=' + page +
        (query !== '' ? '&qg1[or]=' + groupField + filterField + query : ''), options);
}

// Realiza la petición de la lista de mensajes enviados.
export function getOutMessageList(page_size, page){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/message/list?page_size=' + page_size + '&page=' + page, options);
}

// Realiza la petición de la lista de tipos de mensajes.
export function getMessageTypes(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/typemessage/list', options);
}

// Realiza la petición de obtener los datos del excel de provincias.
export function getProvinceSeasonList(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/dashboard/province', options);
}

// Realiza la petición de obtener los datos del excel de municipios.
export function getTownSeasonList(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/dashboard/town', options);
}

// Realiza la petición de obtener la petición asíncrona realizada anteriormente.
export function getJobAsync(id){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'job/status/' + id, options);
}

// Realiza la petición para obtener el resultado de la petición asíncrona finalizada.
export function getJobAsyncResultAmazon(url){
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET'
    };
    return fetch(url, options);
}

// Realiza la petición para obtener el resultado de la petición asíncrona finalizada.
export function getJobAsyncResult(id){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'job/result/' + id, options);
}

// Obtiene el número de explotaciones de un usuario.
export function getUserFarms(id){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'resource/farm/count?userid=' + id, options);
}

// Realiza la petición de la lista de casas comerciales del sistema.
export function getCompanyNoPag(query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/company/list/?name[in]=' + query, options);
}

// Realiza la petición de la lista de organizaciones del sistema.
export function getOrganizationList(page_size, page, query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/organization/list/?page_size=' + page_size + '&page=' + page +
        (query !== '' ? '&qg1[or]=name,id&name[in]=' + query + '&id[eq]=' + query : ''), options);
}

// Obtiene las explotaciones de un usuario de forma paginada.
export function getUserSeasonPaginated(id, page, page_size){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/season/list?userid=' + id + '&page_size=' + page_size + '&page=' + page, options);
}

// Obtiene todas las explotaciones de un usuario.
export function getAllUserSeasons(id){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/season/list?userid=' + id, options);
}

// Obtiene la lista de todas las explotaciones del servidor.
export function getAllSeasonsPaginatedAndFiltered(query, page, page_size){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/season/list?page_size=' + page_size + '&page=' + page +
        (query !== '' ? '&name[in]=' + query : ''), options);
}

// Obtiene la lista de todas las explotaciones del usuario x del servidor.
export function getAllSeasonsPaginatedAndFilteredByUser(query, page, page_size, idOrg){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    //Si el ID de la organización es 0 se considera que no se quiere aplicar filtro de búsqueda.
    let orgFilter = (idOrg !== 0) ? "&qg1[and]=org&org[eq]=" + idOrg: "";
    return fetch(strings.API_URL + 'sysadmin/season/list?page_size=' + page_size + '&page=' + page + orgFilter +
        (query !== '' ? '&name[in]=' + query : ''), options);
}

// Obtiene la lista de roles par explotación.
export function getRoleList(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/role/list', options);
}

// Obtiene la lista de tratamientos del vademecum.
export function getVademecum(page_size, page, query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/typephytosanitary/list?page_size=' + page_size + '&page=' + page +
        (query !== '' ? query : '') + '&simplified', options);
}

// Obtiene la lista de tratamientos del vademecum.
export function getPhytoTPCI(phytoId){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/tpci/list?typephytosanitary[eq]=' + phytoId, options);
}

// Obtiene la lista de tratamientos del vademecum.
export function getPhytoTPCIFiltered(phytoId, cropId, problemId){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/tpci/list?typephytosanitary[eq]=' + phytoId + (cropId !== null ? '&typecrop[eq]=' + cropId : '') +
        (problemId !== null ? '&infection[eq]=' + problemId : ''), options);
}

// Realiza la petición de la lista de cultivos del sistema que cumplen con el filtro.
export function getCropListFilter(query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/typecrop/list' + (query !== undefined ? '?qg1[or]=name,code&name[in]=' + query + '&code[in]=' + query : ''), options);
}

// Realiza la petición de la lista de problemas del sistema que cumplen con el nombre indicado.
export function getProblemListFilter(query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/infection/list' + (query !== undefined ? '?name[in]=' + query : ''), options);
}

// Realiza la petición de la lista de materias activas del sistema que cumplen con el nombre indicado.
export function getSubstanceListFilter(query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/substance/list?name[in]=' + query, options);
}

// Pide la lista de los tipos de protección.
export function getTypeProtection(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/typeprotection/list', options);
}

// Pide la lista de medidas de dosis, caldo, etc.
export function getMeasure(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/measure/list', options);
}

// Obtiene la lista de tratamientos con el nombre o código indicado.
export function getTypePhytosanitary(query){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/typephytosanitary/list'  + (query !== undefined ? '?qg1[or]=name,code&name[in]=' + query
        + '&code[in]=' + query : '') + '&simplified', options);
}

// Obtiene la lista de tipos de key.
export function getTypeKey(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/typekey/list', options);
}

// Obtiene la lista de tipos de organización.
export function getTypeOrganization(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'master/typeorganization/list', options);
}

// Obtiene las licencias de la organización con el id pasado.
export function getOrgLicenseServer(id, page, page_size, query) {
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/key/list?qg1[or]=idorg&idorg[eq]=' + id + (page !== undefined ? '&page=' + page + '&page_size=' + page_size : '') +
        (query !== undefined && query !== "" ? '&qg2[or]=key,loginname&key[eq]=' + query + '&loginname[in]=' + query : ''), options);
}

// Obtiene las licencias de la organización con el id pasado de forma asíncrona.
export function getAsyncOrgLicenseServer(id) {
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/key/list/async?qg1[or]=idorg&idorg[eq]=' + id, options);
}

/********************************************************************************
 * Servicios POST                                                               *
 ********************************************************************************/
// Realiza la petición de log in al servidor
export function logInServer(loginname, password){
    let strings = new Strings();
    let json = {
        grant_type: "password",
        client_id: strings.CLIENT_ID,
        client_secret: strings.CLIENT_SECRET,
        username: loginname,
        password: password
    };

    let formBody = [];
    for (let property in json) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(json[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let options = {
        method: 'POST',
        body: formBody,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    };
    return fetch(strings.API_URL + "oauth2/authentication", options);
}

// Actualiza la duración del token bearer.
export function refreshToken(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let json = {
        grant_type: "refresh_token",
        client_id: strings.CLIENT_ID,
        client_secret: strings.CLIENT_SECRET,
        refresh_token: "" + credentials.refresh_token
    };

    let formBody = [];
    for (let property in json) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(json[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    let options = {
        method: 'POST',
        body: formBody,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' },
    };
    return fetch(strings.API_URL + "oauth2/authentication", options);
}

// Envia un mensaje general a todos los usuarios de oSIGris.
export function createOsigrisMessage(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/message/osigris', options);
}

// Envia un mensaje general a todos los usuarios de oSIGris con explotaciones en Galicia.
export function createAreeiroMessage(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/message/areeiro', options);
}

// Realiza la petición de crear o editar una organización con los datos pasados.
export function createOrganizationServer(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/organization/async', options);
}

// Realiza la petición de crear o actualizar una licencia con los datos pasados.
export function createLicenseServer(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/key/', options);
}

// Actualiza personal de la explotación.
export function updateSeasonTeam(seasonId, json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/season/' + seasonId + '/team/list', options);
}

// Crea o actualiza el TPCI indicado.
export function updateTPCI(idPhyto, json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/typephytosanitary/' + idPhyto + '/tpci', options);
}

/********************************************************************************
 * Servicios PUT                                                                *
 ********************************************************************************/
// Actualiza la información básica del sistema.
export function updateBasicInfo(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/system/basic', options);
}

// Actualiza la información fitosanitaria del sistema.
export function updatePhytoInfo(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/system/phytosanitary', options);
}

// Actualiza la información sigpac del sistema.
export function updateSigpacInfo(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/system/sigpac', options);
}

// Actualiza la información hash del sistema.
export function updateHashInfo(){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        }
    };
    return fetch(strings.API_URL + 'sysadmin/system/hash', options);
}

// Realiza la petición de logout.
export function logout(){
    let strings = new Strings();
    let credentials = strings.getCredentials();

    let json = {
        token_type_hint: "refresh_token",
        client_id: strings.CLIENT_ID,
        client_secret: strings.CLIENT_SECRET,
        token: "" + credentials.refresh_token
    };

    let formBody = [];
    for (let property in json) {
        let encodedKey = encodeURIComponent(property);
        let encodedValue = encodeURIComponent(json[property]);
        formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    // noinspection JSUnresolvedVariable
    let options = {
        method: 'POST',
        body: formBody,
        headers: { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
            'Authorization': 'Bearer ' + credentials.access_token},
    };

    return fetch(strings.API_URL + "oauth2/revoke", options);
}

// Actualiza el TypePhytosanitary indicado.
export function updateTypephytosanitary(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/typephytosanitary', options);
}

/********************************************************************************
 * Servicios DELETE                                                             *
 ********************************************************************************/
// Realiza la petición de borrado del mensaje pasado.
export function deleteMessage(json, areeiro){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    let url = (areeiro === true ? 'areeiro' : 'osigris');
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/message/' + url, options);
}

// Elimina un usuario pasado.
export function removeUser(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/user/', options);
}

// Realiza la petición de eliminar una organización con los datos pasados.
export function deleteOrgServer(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/organization/', options);
}

// Realiza la petición de eliminar la licencia con los datos pasados.
export function deleteLicense(json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/key/', options);
}

// Elimina personal de la explotación.
export function deleteSeasonTeam(seasonId, json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/season/' + seasonId + '/team/list', options);
}

// Elimina el TPCI indicado.
export function deleteTPCI(idPhyto, json){
    let strings = new Strings();
    let credentials = strings.getCredentials();
    // noinspection JSUnresolvedVariable
    let options = {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + credentials.access_token
        },
        body: JSON.stringify(json, null, 0)
    };
    return fetch(strings.API_URL + 'sysadmin/typephytosanitary/' + idPhyto + '/tpci', options);
}